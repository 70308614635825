import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ba4dcf9 = () => interopDefault(import('../modules/qgm_module_ui/pages/base/index.vue' /* webpackChunkName: "" */))
const _0250f42d = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67ec50bf = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _06de17b1 = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _4922d6c6 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _ebdb85a6 = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _011b4f32 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _8faf56b8 = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _6d37982a = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _90a8a616 = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _2329ad5e = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _8405c7d6 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _21fbbed9 = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _55a2c71f = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _4ca9c5e5 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _0ae05a62 = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _70d2a700 = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _3930561e = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _4cfa2067 = () => interopDefault(import('../uicore/pages/arm/admin/system/registryLayers.vue' /* webpackChunkName: "pages/arm/admin/system/registryLayers" */))
const _151755a6 = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _53fdec4a = () => interopDefault(import('../uicore/pages/arm/admin/system/layers.vue' /* webpackChunkName: "pages/arm/admin/system/layers" */))
const _01200bf6 = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _3ce71846 = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _2086566e = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _fb452c9c = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _4f759c01 = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _1c7ac19e = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _6b83d821 = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _829acaca = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _3ece0edb = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _1b0b9952 = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _b5d96354 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _07c833c5 = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _22e3ff90 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _7f34cbbb = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _c6151476 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _b0802898 = () => interopDefault(import('../uicore/pages/base/registries.vue' /* webpackChunkName: "pages/base/registries" */))
const _7acf8577 = () => interopDefault(import('../uicore/pages/base/geoportal/index.vue' /* webpackChunkName: "pages/base/geoportal/index" */))
const _36f44e66 = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _57ad5063 = () => interopDefault(import('../uicore/pages/arm/registries.vue' /* webpackChunkName: "pages/arm/registries" */))
const _2e52bfde = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _6a00f9d1 = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _f37e93b0 = () => interopDefault(import('../uicore/pages/arm/geoportal/index.vue' /* webpackChunkName: "pages/arm/geoportal/index" */))
const _10e3181c = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _3bcfa7b2 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _bf4d528c = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/base",
    component: _3ba4dcf9,
    name: "base"
  }, {
    path: "/",
    component: _0250f42d,
    name: "index"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _67ec50bf,
    name: "base-check_doc-fileId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _06de17b1,
    name: "arm-resetPass-token"
  }, {
    path: "/base/service/list/:parentId?",
    component: _4922d6c6,
    name: "base-service-list-parentId"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _ebdb85a6,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _011b4f32,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/application/process/:asId?",
    component: _8faf56b8,
    name: "base-application-process-asId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _6d37982a,
    name: "base-application-monitor-appId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _90a8a616,
    name: "arm-service-list-parentId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _2329ad5e,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _8405c7d6,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _21fbbed9,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _55a2c71f,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _4ca9c5e5,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/base/application/result/appId",
    component: _0ae05a62,
    name: "base-application-result-appId"
  }, {
    path: "/arm/application/view/asId",
    component: _70d2a700,
    name: "arm-application-view-asId"
  }, {
    path: "/arm/admin/system/route",
    component: _3930561e,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/admin/system/registryLayers",
    component: _4cfa2067,
    name: "arm-admin-system-registryLayers"
  }, {
    path: "/arm/admin/system/list",
    component: _151755a6,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/layers",
    component: _53fdec4a,
    name: "arm-admin-system-layers"
  }, {
    path: "/arm/admin/system/control",
    component: _01200bf6,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/calendar",
    component: _3ce71846,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/app",
    component: _2086566e,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/org/service",
    component: _fb452c9c,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/org/employee",
    component: _4f759c01,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/app",
    component: _1c7ac19e,
    name: "arm-admin-org-app"
  }, {
    path: "/base/auth/register",
    component: _6b83d821,
    name: "base-auth-register"
  }, {
    path: "/base/auth/profile",
    component: _829acaca,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/login",
    component: _3ece0edb,
    name: "base-auth-login"
  }, {
    path: "/base/auth/forgotPass",
    component: _1b0b9952,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/application/list",
    component: _b5d96354,
    name: "base-application-list"
  }, {
    path: "/arm/application/list",
    component: _07c833c5,
    name: "arm-application-list"
  }, {
    path: "/arm/application/income",
    component: _22e3ff90,
    name: "arm-application-income"
  }, {
    path: "/oldAppsList/employee",
    component: _7f34cbbb,
    name: "oldAppsList-employee"
  }, {
    path: "/oldAppsList/applicant",
    component: _c6151476,
    name: "oldAppsList-applicant"
  }, {
    path: "/base/registries",
    component: _b0802898,
    name: "base-registries"
  }, {
    path: "/base/geoportal",
    component: _7acf8577,
    name: "base-geoportal"
  }, {
    path: "/base/faq",
    component: _36f44e66,
    name: "base-faq"
  }, {
    path: "/arm/registries",
    component: _57ad5063,
    name: "arm-registries"
  }, {
    path: "/arm/profile",
    component: _2e52bfde,
    name: "arm-profile"
  }, {
    path: "/arm/login",
    component: _6a00f9d1,
    name: "arm-login"
  }, {
    path: "/arm/geoportal",
    component: _f37e93b0,
    name: "arm-geoportal"
  }, {
    path: "/arm/forgotPass",
    component: _10e3181c,
    name: "arm-forgotPass"
  }, {
    path: "/Test",
    component: _3bcfa7b2,
    name: "Test"
  }, {
    path: "/arm",
    component: _bf4d528c,
    name: "arm"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
